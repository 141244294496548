/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-02-15",
    versionChannel: "nightly",
    buildDate: "2024-02-15T00:15:18.016Z",
    commitHash: "49b3b9d7dee7c830214aa5c40dce839abdcbe13a",
};
